
.LandingPanelBg {
	background-position: bottom center;
	background-size: cover;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	display: flex;
	flex-direction: row;
	text-align: center;
	justify-content: center;
	align-items: center;
}

.LandingLink, .LandingBackLink {
	text-decoration: none !important;
	border: 0 !important;
}

.LandingLink {
	color: #000 !important;
	width: calc(100% + 30px);
	margin-left: -15px;
	display: block;
	text-align: center;
}

.LandingBackLink {
	color: #aaa !important;
}

.LandingPanel {
	background: #fff;
	border: 1px solid #ddd;
	box-sizing: border-box;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.36), 0 0 10px rgba(0, 0, 0, 0.63);
	border-radius: 2px;
	padding: 15px;
	padding-bottom: 35px;
	margin: auto;

	width: 500px;
}

.LandingPanelHeader {
	width: calc(100% + 32px);
	height: 120px;
	margin-top: -16px;
	margin-left: -16px;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	background: var(--primary);
}

.LandingPanelLogo {
	width: 100%;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 40%;
}

.LandingPanelForm {
	padding-top: 10px;
	margin: 0 20px;
	text-align: center;
}

.LandingPanelInput {
	min-height: 40px;
	margin: 24px 4px;
	text-align: left;
}

.LandingPanelQR {
	display: flex;
	justify-content: center;
	align-items: center;
}

.LandingPanelFormList {
	text-align: left;
}

.LandingPanelForm svg {
	top: 20px !important;
	height: 30px !important;;
	width: 30px !important;;
}

.LandingPanelSubmit {
	color: #fff !important;
	width: 220px !important;
	/*height: 50px !important;*/
	box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.32);
	margin-top: 20px !important;
}

.LandingPanelForget {
	background: #fff !important;
	margin-top: 10px !important;
	color: #aaa !important;
}

.LandingPanelDescription {
	background: #d2d7d3;
	text-align: center;
	padding: 10px;
	margin-left: -15px;
	width: calc(100% + 30px);
	box-sizing: border-box;
}

.AuthLangSelectorBox {
	position: fixed;
	right: 20px;
	top: 10px;

}

@media screen and (max-width: 600px), screen and (max-height: 500px) {
	.LandingPanel {
		width: 100%;
		height: 100%;
		margin-top: 0px;
		margin-left: 0px;
		top: 0px;
		left: 0px;
		padding: 5px;
		border-radius: 0px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.LandingPanelForm {
		margin: 0px 5px;
	}
}
