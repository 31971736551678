
.View {
	border-radius: 3px;
	padding: 32px;
	padding-bottom: 36px;
}

.FullScreenView {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow: auto;

	background-color: #fff;
	padding: 32px;
}