
:root {
	--current-color: var(--secondary);;
	--accent-color: #000;
	--slash-color: #dedede;
}

.ViewBreadcrumb {
	width: calc(100% + 40px);

	text-align: center;
	display: flex;
	overflow: hidden;

	margin-left: -20px;
	margin-right: -20px;
	margin-top: -12px;

}

.ViewBreadcrumb > a {
	text-decoration: none;
	outline: none;
	display: block;
	font-size: 12px;
	line-height: 36px;
	color: #fff;
	padding: 0 10px 0 32px;
	position: relative;
	text-align: left;
	text-transform: uppercase;
	border-bottom: 1px solid #dedede;
}

.ViewBreadcrumb a:first-child {
	padding: 0 10px 0 28px;
}

.ViewBreadcrumb a:last-child {
	flex-grow: 1;
	cursor: default;
	background: #fff !important;
	color: var(--current-color);
}

.ViewBreadcrumb a:last-child::after {
	content: none;
}

.ViewBreadcrumb a::after {
	content: '';
	position: absolute;
	top: 0;
	right: -18px;
	width: 36px;
	height: 36px;
	transform: scale(0.707) rotate(45deg);
	z-index: 1;
	box-shadow: 1px -1px 0 1px var(--slash-color);
	border-radius: 0 5px 0 50px;
}

.ViewBreadcrumb a,
.ViewBreadcrumb a::after {
	background: #fff;
	color: var(--accent-color);
	transition: all 0.5s;
}

.ViewBreadcrumb a:hover,
.ViewBreadcrumb a.active,
.ViewBreadcrumb a:hover::after,
.ViewBreadcrumb a.active::after {
	background: var(--slash-color);
}

.ActiveBreadCrumb {

}