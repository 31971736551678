body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


:root {
	--primary: #36302f;
	--secondary: #c29d67;

	--primary-dec: 54,48,47;
}

/* Chrome, Safari, Edge, Opera */
.no-inpt-arrows input::-webkit-outer-spin-button, .no-inpt-arrows input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.no-inpt-arrows input[type=number] {
	-moz-appearance: textfield;
}