.LeftMenu {
	position: fixed;
	z-index: 4;
	width: 250px;
	height: calc(80% - 200px);
	top: 200px;
	bottom: 0;
	left: 0;
	text-align: left;
	/*box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.26), 0px 3px 10px rgba(0, 0, 0, 0.43);*/
	overflow: auto;
	/*background: #fff;*/
}

.LeftMenu .MenuLink div div {
	padding: 0 2px !important;
}

.LeftMenu .MenuLink, .LeftMenu .ActiveMenuLink, .LeftMenu .MenuLink li div h3, .LeftMenu .ActiveMenuLink li div h3 {
	/*color: #ccc !important;*/
	text-decoration: none !important;
	border: 0 !important;
	text-transform: none;
	padding-top: 2px;
	padding-bottom: 2px;
	letter-spacing: 0.1px;
	/*font-weight: 600;*/
	font-size: 13px;
}

.LeftMenu .MenuLink {
	color: var(--primary);
}

.LeftMenu .ActiveMenuLink {
	color: var(--secondary);
}


.ActiveMenuLink > div {
	border-left: 6px solid var(--secondary) !important;
}

.ActiveMenuLink > div > div {
	margin-left: -6px;
	margin-right: 6px;
}

.LeftMenu .ActiveMenuLink * {
	fill: var(--secondary) !important;
}

@media screen and (max-width: 950px) {
	.LeftMenu {
		display: none;
	}
}

.DrawerLeftMenu {
	top: 80px;
	height: calc(100% - 100px);
	display: block !important;
}

.MenuBody {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 100%;
}
