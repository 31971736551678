
.ViewHeader {
	background: var(--primary);
	color: #fff;
	font-weight: bold;
	font-size: 23px;
	line-height: 32px;
	border-bottom: 1px solid #fff;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;

	padding: 24px 24px !important;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;

	margin: -20px !important;
	max-width: calc(100% + 40px) !important;
	flex-basis: calc(100% + 40px) !important;
	margin-bottom: 12px !important;
}

.ViewHeaderName {
	margin: 0 10px;
}

.HeaderActionButtonContainer {
	width: 80px;
	margin-left: auto;
	padding-top: 10px;

}

.HeaderActionButtonItem {
	position: absolute;
	z-index: 50;
}

.ViewHeaderButtons {
	margin: -7px;
	margin-left: auto;
	font-weight: normal;

}

.ViewHeaderBtnIconBox {
	padding: 0 !important;
	/*width: 30px !important;*/
	/*height: 30px !important;*/
}

.ViewHeaderBtnIcon {
	width: 32px !important;
	height: 32px !important;
}

/*.ViewHeaderButtons button div {*/
/*line-height: 32px;*/
/*}*/