.AppHeader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 60px;
	z-index: 4;
	color: #fff;
	transition: all 1s;
	display: flex;
	/*background: rgba(255, 255, 255, 0.65);*/
	background-color: rgba(var(--primary-dec), 0.65);
}

.AppHeaderContent {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	flex: 1 100%;
}

.AppHeaderShadow {
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.36), 0 0 10px rgba(0, 0, 0, 0.63);
	/*background: rgba(255, 255, 255, 1);*/
	background: var(--primary);
}

.Hamburger {
	align-self: flex-start;
	margin: auto;
	padding-left: 12px;
	display: none;
}

.AppHeaderLogo {
	align-self: flex-start;
	margin: auto;
	padding-left: 22px;
	cursor: pointer;
}

@media screen and (max-width: 950px) {
	.Hamburger {
		display: block;
	}

	.AppHeaderLogo {
		padding-left: 10px;
	}
}

.AppHeaderCenter {
	align-self: center;
	text-align: center;
	margin: auto;
	margin-right: 10px;
	flex: 3 0;
}

.AppHeaderActions {
	align-self: flex-end;
	margin: auto;
	padding-right: 20px;
}

.MailsBadge {
	margin: 15px;
	z-index: 5;
	background: #fb101b;
	width: 17px !important;
	height: 17px !important;
	font-weight: bold;
}

.FlagMenuBox {
	width: 35px;
	height: 30px;
	margin-left: 10px;
}

.Flag, .FlagMenuItem div div div::before {
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	background-position: center center;
}

.Flag {
	height: 18px;
	width: 30px;
	position: absolute;
	margin-top: 10px;
}

.FlagMenuItem div div div::before {
	content: " ";
	padding-left: 20px;
	margin-left: -15px;
	margin-right: 10px;
}

.ENFlag, .ENFlagMenuItem div div div::before {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNjAgMzAiIGhlaWdodD0iNjAwIj4NCjxkZWZzPg0KPGNsaXBQYXRoIGlkPSJ0Ij4NCjxwYXRoIGQ9Im0zMCwxNWgzMHYxNXp2MTVoLTMwemgtMzB2LTE1enYtMTVoMzB6Ii8+DQo8L2NsaXBQYXRoPg0KPC9kZWZzPg0KPHBhdGggZmlsbD0iIzAwMjQ3ZCIgZD0ibTAsMHYzMGg2MHYtMzB6Ii8+DQo8cGF0aCBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iNiIgZD0ibTAsMGw2MCwzMG0wLTMwbC02MCwzMCIvPg0KPHBhdGggc3Ryb2tlPSIjY2YxNDJiIiBzdHJva2Utd2lkdGg9IjQiIGQ9Im0wLDBsNjAsMzBtMC0zMGwtNjAsMzAiIGNsaXAtcGF0aD0idXJsKCN0KSIvPg0KPHBhdGggc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEwIiBkPSJtMzAsMHYzMG0tMzAtMTVoNjAiLz4NCjxwYXRoIHN0cm9rZT0iI2NmMTQyYiIgc3Ryb2tlLXdpZHRoPSI2IiBkPSJtMzAsMHYzMG0tMzAtMTVoNjAiLz4NCjwvc3ZnPg0K')
}

.PLFlag, .PLFlagMenuItem div div div::before {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjgwIDgwMCI+DQo8cGF0aCBmaWxsPSIjZTllOGU3IiBkPSJtMCwwaDEyODB2NDAwaC0xMjgweiIvPg0KPHBhdGggZmlsbD0iI2Q0MjEzZCIgZD0ibTAsNDAwaDEyODB2NDAwaC0xMjgweiIvPg0KPC9zdmc+DQo=')
}

.DEFlag, .DEFlagMenuItem div div div::before {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjYwMCIgdmlld0JveD0iMCAwIDUgMyI+DQo8cGF0aCBkPSJtMCwwaDV2M2gtNXoiLz4NCjxwYXRoIGZpbGw9IiNkMDAiIGQ9Im0wLDFoNXYyaC01eiIvPg0KPHBhdGggZmlsbD0iI2ZmY2UwMCIgZD0ibTAsMmg1djFoLTV6Ii8+DQo8L3N2Zz4NCg==')
}

.FRFlag, .FRFlagMenuItem div div div::before {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5MDAgNjAwIj4NCjxwYXRoIGZpbGw9IiNlZDI5MzkiIGQ9Im0wLDBoOTAwdjYwMGgtOTAweiIvPg0KPHBhdGggZmlsbD0iI2ZmZiIgZD0ibTAsMGg2MDB2NjAwaC02MDB6Ii8+DQo8cGF0aCBmaWxsPSIjMDAyMzk1IiBkPSJtMCwwaDMwMHY2MDBoLTMwMHoiLz4NCjwvc3ZnPg0K')
}

.ESFlag, .ESFlagMenuItem div div div::before {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3NTAgNTAwIj4NCjxwYXRoIGZpbGw9IiNjNjBiMWUiIGQ9Im0wLDBoNzUwdjUwMGgtNzUweiIvPg0KPHBhdGggZmlsbD0iI2ZmYzQwMCIgZD0ibTAsMTI1aDc1MHYyNTBoLTc1MHoiLz4NCjwvc3ZnPg0K')
}

.RUFlag, .RUFlagMenuItem div div div::before {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NTAgMzAwIj4NCjxwYXRoIGZpbGw9IiNmZmYiIGQ9Im0wLDBoNDUwdjEwMGgtNDUweiIvPg0KPHBhdGggZmlsbD0iIzAwZiIgZD0ibTAsMTAwaDQ1MHYxMDBoLTQ1MHoiLz4NCjxwYXRoIGZpbGw9IiNmMDAiIGQ9Im0wLDIwMGg0NTB2MTAwaC00NTB6Ii8+DQo8L3N2Zz4NCg==')
}
