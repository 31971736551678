.NotFoundBg {
	background-position: center center;
	background-size: cover;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
}

.NotFoundPanelWrapper {
	top: 50%;
	left: 50%;
	margin-left: -260px;
	margin-top: calc( -160px - 10vh );
	width: 520px;
	height: 320px;
	position: fixed;
	display: flex;
	flex-direction: row;
	text-align: center;
	justify-content: center;
	align-items: center;
}

.NotFoundPanel {
	color: #fff;
	width: 520px;
	box-sizing: border-box;
	border-radius: 2px;
	padding: 15px;
	padding-bottom: 35px;
	margin: auto;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.NotFoundFont {
}

.NotFoundPaper {
	width: 520px;
	padding: 24px;
	box-sizing: border-box;
	background: rgba(255,255,255,0.75) !important;
}

.NotFoundPanel svg {
	display: block;
	font-size: 35vh;
	width: 520px;
	height: 30vh;
	margin: 0 auto;
}

.NotFound404 {
	fill: none;
	stroke: white;
	stroke-dasharray: 6% 29%;
	stroke-width: 5px;
	stroke-dashoffset: 0%;
	animation: stroke-offset 5.5s infinite linear;
}

.NotFound404:nth-child(1){
	stroke: var(--primary);
	animation-delay: -1;
}

.NotFound404:nth-child(2){
	stroke: var(--secondary);
	animation-delay: -2s;
}

.NotFound404:nth-child(3){
	stroke: #FFF;
	animation-delay: -3s;
}

.NotFound404:nth-child(4){
	stroke: #000;
	animation-delay: -4s;
}

.NotFound404:nth-child(5){
	stroke: #AAA;
	animation-delay: -5s;
}

@keyframes stroke-offset{
	100% {stroke-dashoffset: -35%;}
}

@media screen and (max-width: 700px) {
	.NotFoundPaper, .NotFoundPanel, .NotFoundPanel svg, .NotFoundPanelWrapper {
		width: 100% !important;
	}

	.NotFoundPanelWrapper {
		left: 0;
		margin-left: 0;
	}
}













