.UserBox {
	position: fixed;
	top: 93px;
	z-index: 10;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	flex: 1 100%;
	padding-left: 16px;
	height: 107px;
}

.UserBoxContent {
	width: 235px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	flex: 1 100%;
	cursor: pointer;
}

.UserBoxAvatar div {
	border: 3px solid var(--primary);
}

.UserBoxInfo {
	color: var(--primary);
	width: 150px;
	text-align: left;
	padding-left: 16px;
	padding-right: 16px;
}

.Username, .EMail {
	width: 100%;
	display: block;
	font-weight: bold;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.Username {
	text-transform: capitalize;
	font-size: 16px;
	text-shadow: 1px 1px 1px rgba(255,255,255,0.5), 1px 1px 5px rgba(255,255,255,0.5), 1px 1px 10px rgba(255,255,255,0.5);
}

.EMail {
	font-size: 10px;
	text-shadow: 1px 1px 1px rgba(255,255,255,0.5), 1px 1px 5px rgba(255,255,255,0.5), 1px 1px 10px rgba(255,255,255,0.5);
}

.UserBoxMenu {
	min-width: 218px !important;
}

.UserBoxAvatar {
	width: 56px !important;
	height: 56px !important;
}

@media screen and (max-width: 950px) {
	.UserBox {
		display: none;
		background: var(--primary);
		height: 72px !important;
	}

	.UserBoxInfo {
		color: #fff;
	}

	.Username, .EMail {
		text-shadow: none;
	}
}
