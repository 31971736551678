body {
	background: #eee;
}

.AppHeader {
	position: fixed;
	width: 100%;
	z-index: 6;
	top: 0;
}

.App {
	text-align: left;
}

.AppBarTitle {
	float: left;
	padding-right: 15px;
}

.AppBarLogo {
	width: 150px;
	padding-top: 18px;
	padding-right: 15px;
	float: left;
}

.HeaderBg {
	position: fixed;
	background-position: bottom left;
	background-size: cover;
	width: 100%;
	height: 200px;
	z-index: 1;
	top: 0;
}

.BodyBg {
	position: fixed;
	z-index: 1;
	top: 200px;
	bottom: 0;
	left: 0;
	right: 0;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.26), 0 0 6px rgba(0, 0, 0, 0.33);
}

.AppBox {
	position: relative;
	margin-left: 250px;
	box-sizing: border-box;
	margin-right: 250px;
	padding-bottom: 72px;
	z-index: 5;

	/* Waypoint fix */
	border-top: 1px solid transparent;
}

.AppContent {
	margin-top: 93px;
	min-height: calc(100vh - 172px);
	width: 100%;
	background: #fcfcfc;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.26), 0 0 6px rgba(0, 0, 0, 0.33);
	z-index: 10;
	border-radius: 3px;
}

.MenuDrawer {
	display: none;
}

.MenuDrawerContent {
	width: 250px;
	background: #222630 !important;
}

@media screen and (max-width: 1500px) {
	.AppBox {
		margin-right: 50px;
	}
}

@media screen and (max-width: 950px) {
	.AppBox {
		margin-right: 25px;
		margin-left: 25px;
	}

	.MenuDrawer {
		display: initial;
	}
}

.AppFooter {
	position: fixed;
	height: 24px;
	bottom: 20px;
	left: 0;
	right: 0;
	z-index: 2;
	display: flex;
	font-size: 16px;
	justify-content: center;
	line-height: 30px;
	align-items: center;
}

